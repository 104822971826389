import {  navigate, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import DataContext from './context/DataContext';
import './Nav.css'
import { isLoggedIn, logOut } from './api/userManagement';
import {Button, Heading, Link, Text } from '@radix-ui/themes';


const Nav = () => {
    const { search, setSearch, isUserLoggedIn,setIsUserLoggedIn, setLoggedInUser } = useContext(DataContext);
    let navigate = useNavigate();
    let location = useLocation();
    let currentPath = location.pathname


    async function logoutUser()
    {
        let result  = await logOut();

        
       

        if(result?.response?.status >=200)
            {
                setIsUserLoggedIn(false)
                setLoggedInUser('')
            } else {
              console.log('logout error')
            }
    }

    useEffect(() =>{

       
        
      
      
        const cleanUp = () => {
            // isMounted = false;
            // controller.abort()
            console.log('cleanup called')
            // source.cancel();
        }
      
        return cleanUp;
      },[isUserLoggedIn]);

    return (
        <nav className="Nav">
            <div><Heading>Clarity</Heading></div>
            
                  {/* links render to anchor text but tell react not to pull react from server but route to proper component. 
                leverages the router we created in parent app */}
            <a className="homeLink" href='/' to="/">Home</a>
            {/* <a href='/accountDetails'><Text color='white'>Account Details</Text></a> */}
            {isUserLoggedIn && <a href='/accountDetails'><Text color='white'>Account Details</Text></a>}
            {/* what if ookies expire but neer leaves broswer/page */}
            {isUserLoggedIn ? <a  href='#' onClick={logoutUser}>Logout</a>: <a className="" onClick={()=> navigate('/login', {state: {pathCameFrom: location.pathname}})} href='' to="">Login</a>}

            {/* {isUserLoggedIn && <a  href='#' onClick={logoutUser}>Logout</a>}
            {!isUserLoggedIn && <a className="" onClick={()=> navigate('/login')} href='' to="">Login</a>} */}

                
        </nav>
    )
}

export default Nav


{/* <ul>
                  
                <li><Link to="/">Home</Link></li>
                <li><Link to="/post">Post</Link></li>
                <li><Link to="/about">About</Link></li>
            </ul> */}