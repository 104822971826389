import React from 'react'
import parse from 'html-react-parser';
import { Strong, Flex, Text, Button, Card, Box, Heading, Link, Em } from '@radix-ui/themes';


const FearTriggerCTA = () => {

      
  return (
    
<Box maxWidth="">
    <Em>Although we think anyone can free themselves of this issue we want to make it known that may take time before you're able to be ok with whatever you need to be ok with to eliminate a trigger/fear. We recommend you grant yourself grace and time because we doubt you're a never before seen perfect human being. </Em>
    <Heading><Strong>Recommended reads</Strong></Heading>
    <ul>

    </ul>
    <li><Link highContrast href="https://medium.com/@theclarityapp/unpacking-the-science-the-psychological-root-cause-of-anxiety-be1efa46d07b">What is the root cause of anxiety?</Link></li>
    {/* <li><Link href="">Is anxiety actualyl helpful or necessary?</Link></li> */}
    {/* <li><Link href="">Common psychological solutions for eliminating a trigger/fear</Link></li> */}
    <li><Text>For more on why and how we use anxiety in ways we are sometimes and likely unaware of, read <a href="https://medium.com/@theclarityapp/were-not-the-only-ones-in-the-psychological-community-who-think-this-but-we-put-forward-that-37e7b2ffdb14">this. </a></Text></li>
    <li><Text>To take a closer look at why anxiety appears to be helpful but often isn’t beneficial in the long run read this(coming soon)</Text>   </li>
    {/* <li><Text>To take a closer look at why anxiety appears to be helpful but often isn’t beneficial in the long run read this.(is anxiety helpful on wordpress)</Text>   </li> */}

    <li><a href="https://drive.google.com/file/d/1_ldd3YVP6UpgJAjh7lgzkIrbfJEzxkMe/view?usp=drive_link">How to reprogram your brain and eliminate an anxiety trigger</a></li>
    <li><Text>Examples of how to uncover and eliminate an anxiety trigger/fear(coming soon)</Text></li>
  </Box>
    
//   <div className="card">
//     <div className="card-details">
//         <p className="text-title">{insight.insightTitle}</p>
//         <p className="text-body">{insight.tags ? `Tags: ${insight.tags}`: ''}</p>
//         <p className="text-body">{insight.insight ? parse(insight.insight.substring(0,100)): ''}</p>
//     </div>
//     <button className="card-button">More info</button>
// </div>
  )
}

export default FearTriggerCTA

