import { Heading, Text } from '@radix-ui/themes'
import React from 'react'
import { useParams, Link, useNavigate } from "react-router-dom";
import parse from 'html-react-parser';


const PostCard = ({title, postContent, author, referencingObjectId, postType, postId, parentObjectName}) => {
    const navigate = useNavigate();



    function bro() {
        console.log("huh")
        console.log(postType)
        console.log(parentObjectName)
        
// all comments or post     http://localhost:4500/posts/belief/66b511731d68834467470d41/post/2/comments

         navigate(`/posts/${postType}/${referencingObjectId}/${parentObjectName}/post/${postId}`)
       // navigate(`/thefishappeins`)


    }
   
  return (
    <button onClick={bro} >
        <Heading>{title}</Heading>
        <Text>{parse(postContent)} </Text>
        <Text>Post author: {author}</Text>
    </button>
  )
}

export default PostCard

