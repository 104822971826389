import logo from '../logo.svg';
// import '../App.css';
import { Link, useParams, useNavigate} from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import DataContext from '../context/DataContext';
import parse from 'html-react-parser';

import { fetchData, deleteData, apiURL, postData } from '../api/fetchData';
import BeliefList from '../BeliefList';
import { Flex, Button, AlertDialog, Text, Heading, Em } from '@radix-ui/themes';

import { checkIfAdmin } from '../Pages/Admin/utility';
import { getConditionsbyType } from '../api/conditions';
import FearTriggerCTA from '../Components/FearTriggerCTA';
import { hasAccessToObject, getAccessInfo } from '../api/userManagement';
import { Link2Icon } from '@radix-ui/react-icons';
import { determineIfLockedUtil } from './conditionUtility';



function searchForBelief(belief) {
  //move to belief list page; iterate through all beleifs and generate a single beleif component for each matching belief
  //use string provided and search through db list of beleifs and find relevatn matches. provide matches in a list for user to scroll through
}
 function ConditionPage() {
    const { loggedInUser,searchedForBelief, setsearchedForBelief, beliefListToDisplay, setBeliefListToDisplay  } = useContext(DataContext);
   const navigate = useNavigate();
    const { id } = useParams();
    const [isAdmin, setIsAdmin] = useState(false);
    const [hasTriggers, setHasTriggers] = useState(false);
    const [pageisLocked, setPageIsLocked] = useState(true);

    const [hasFears, setHasFears] = useState(false);
    const [conditionToDisplay, setConditionToDisplay] = useState(null);

    

    

    
  const getCondition = async ()=> {
    // let apiURL = process.env.REACT_APP_API_SERVER + process.env.REACT_APP_PORT;

  
    let fetchedCondition = await fetchData(apiURL + `/conditions/${id}`);
    console.log(fetchedCondition)
    fetchedCondition = fetchedCondition?.response?.data;
   // setConditionsToDisplay(fetchedConditions);
   //get beleifs for condition - find beliefs that have this condition id 
//set condition list so conditon page can use to render a beleif list

  setConditionToDisplay(fetchedCondition)
  let fetchedBeliefs = await fetchData(apiURL + `/beliefs/condition/${id}`);
  console.log(fetchedBeliefs);
  //set beleif list which will be used to create belief cards on next page
  setBeliefListToDisplay(null)
  if(fetchedBeliefs?.response?.data.length >0)
   setBeliefListToDisplay(fetchedBeliefs?.response?.data);
  console.log(fetchedBeliefs?.response?.data);

}

const deleteCondition = async () => {
  console.log(id)
  console.log(`id we lliking for ${id}`)
  // let apiURL = process.env.REACT_APP_API_SERVER + process.env.REACT_APP_PORT;
  let reqBody = {'id':id}
  let deletedCondition = await deleteData( apiURL + `/conditions/${id}`, reqBody);
  console.log(`deleted condition for 1 pager is ${deletedCondition.response}`);
  //console.log(deletedBelief?.response.data);

  //deletedBelief = deletedBelief.response.data

  //going to this page means we fetch a belief that wont have conditions field of objects set 
  
  setConditionToDisplay(null)
    navigate('/conditions')

}


async function checkForMore() {
  //make api call to get conditions of type trigger with this condition jd /conditions/type
  let triggers = await getConditionsbyType(id, 'trigger')
  console.log(triggers)
  if(triggers.length > 0)
    setHasTriggers(true)


let fears = await getConditionsbyType(id, 'fear')
console.log(fears)
  if( fears.length > 0)
    setHasFears(true)

}


async function isLocked(condition, access){

  let showLock = false;
 showLock = await determineIfLockedUtil(condition, access)
 console.log('wtf is locked0' + showLock)
/*
  let globalConditionAccess = await fetchData(apiURL + `/user/access/condition`);
  console.log(globalConditionAccess)
  globalConditionAccess = globalConditionAccess?.response?.data?.accesses.user
  console.log(globalConditionAccess)

  // console.log(globalConditionAccess?.response?.data?.accesses)
  // console.log(globalConditionAccess?.response?.data?.type)


  
  //logged in and not a condition free to all
  if(access && !globalConditionAccess.includes(condition._id) )
    {
      console.log(access)
      let parentAccess = false;
      if(condition?.parentConditions && condition?.parentConditions.length >=1)
      {
        //check if we have access to any parent conditions
        condition?.parentConditions.forEach((parentId,i) => {
          if(access[parentId])
          {
            parentAccess = true;
          }
              
        })
        //are any of these parent condition in global list. check if global list contains a parent condition
       console.log('run the test'+ condition?.parentConditions.every(parentCondition => globalConditionAccess.includes(parentCondition)))
        console.log('parent access' + parentAccess + condition?.parentConditions.includes(globalConditionAccess))
        //check if parent is social anxiety only if we dont already have access
        if(!parentAccess && condition?.parentConditions.every(parentCondition => globalConditionAccess.includes(parentCondition)) )
          parentAccess=true;
      }
      console.log(access['subscribed'] || access[condition._id])
      //if no access then show lock should be true
       showLock = (access['subscribed'] || access[condition._id] || parentAccess ) ? false : true
      //not logged in with access object and condition isn't social anxiety or a child condition of SA
    } else if( !access && condition._id!=='66df6fd0e95bed6217424ab1' && !condition?.parentConditions  )
    {          
      showLock=true
      }
      //no access, not SA parent condition but a child condition
     else if( !access && condition._id!=='66df6fd0e95bed6217424ab1' && condition?.parentConditions  )
      {
        //if has a parent condition and parent dont contain social anxiety
        if (condition?.parentConditions.length >=1 && !condition?.parentConditions.every(parentCondition => globalConditionAccess.includes(parentCondition)))
        {console.log('in here?')
          showLock=true
        }
      }
        */

      return showLock
  
}




useEffect(() =>{


async function init() {
  if(!conditionToDisplay) //tags havent been retrieved for this session (not in localstorage or current state)
  {
    console.log('is this being called')

    await getCondition();
  } 

  await checkIfAdmin(setIsAdmin); 
  if(isAdmin)
    setPageIsLocked(false)
  else
  {
    console.log(loggedInUser)
    let accessObject = await getAccessInfo(loggedInUser)
    console.log(accessObject);
    console.log(conditionToDisplay)
    if(conditionToDisplay)
    {
      let access = await isLocked(conditionToDisplay,accessObject);
      console.log(access)
    setPageIsLocked(access)

    }
    // if(!conditionToDisplay.parentConditions || conditionToDisplay.parentConditions.length==0)
    //   {
    //     access = await hasAccessToObject(id, accessObject)
    //   } 
    //   else{
    //     //check if user has access to anything in the parent list

    //   }
    

  }

  
  

  await checkForMore();

  //if anxiety, get triggers, fears, and set to pass to conditionlist to render
}

 init()
 



  const cleanUp = () => {
      // isMounted = false;
      // controller.abort()
      console.log('cleanup called')
      // source.cancel();
  }

  return cleanUp;
},[loggedInUser, conditionToDisplay]); //not sure fi this is actually needed

    
    
async function purchaseCondition() {
  let payload =  {
    "id": id,
    "username": loggedInUser,
    'objectType': 'condition',
    "successURL": apiURL + `conditionPurchase/${id}?success=true`,
    "cancelURL": apiURL + `conditionPurchase/${id}?canceled=true`
  }
  let {response} = await postData(apiURL + `/payment/createCheckoutSession`,payload);
  console.log(response)
  console.log(response.status)

  if(response && response?.status>=200)
      window.location.href = response?.data?.redirect
 // setConditionsToDisplay(fetchedConditions);


}
 
    console.log(conditionToDisplay);
    
  return (
   //sometyime typeis in beleif they;re looking for
   //toggel and search by problem instead
  <div>
    {conditionToDisplay && <h1>{conditionToDisplay.condition} </h1>}
    {/* updte this to be a array in list pulled from api */}
    {(conditionToDisplay && (!pageisLocked || id==='')) && 
      <div className=''>
        
        <a style={{
          
        }} href="https://forms.gle/os4qba2HZpTcvoWk6">
        <Button  radius="Full"  style={{
          
        }} size="4" variant="solid" highContrast >Provide feedback to improve this solution<Link2Icon />
        </Button>
      </a>
        {/* <h2>Our Definition: </h2> */}
        {conditionToDisplay?.ourDefinition && parse(conditionToDisplay?.ourDefinition )}
        {conditionToDisplay?.thirdPartyDefinition?.sourceLink && <h3>Definition from  <a href={conditionToDisplay?.thirdPartyDefinition?.sourceLink}>{conditionToDisplay?.thirdPartyDefinition?.sourceLink}</a> </h3>}
        <p>{conditionToDisplay?.thirdPartyDefinition?.definition && parse(conditionToDisplay.thirdPartyDefinition.definition )}</p>
       { beliefListToDisplay && <><h2>Beliefs (if any) that give rise to this condition are below:</h2> 
        {/* belieflist shared state variable is set in condition list before navigateing to this page. will always be set because we either pass in from search or set when we pull condition data */}
        <Flex wrap="true"> <BeliefList ></BeliefList></Flex></>}

        {/* tirggers people have, fears people face,  */}
        {hasTriggers && <Button color="red" mr="2" highContrast size="3" onClick={() => navigate(`/condition/${id}/trigger/${conditionToDisplay.condition}`)}>Different triggers someone could have</Button>}
        {hasFears && <Button color="blue" mr="2" highContrast size="3" onClick={() => navigate(`/condition/${id}/fear/${conditionToDisplay.condition}`)}>Different fears one could have</Button>}

        {(conditionToDisplay?.type==='fear' || conditionToDisplay?.type==='trigger') && <FearTriggerCTA></FearTriggerCTA>}

        <Flex>
           <Button size="3" mt="3" highContrast onClick={() => navigate(`/posts/condition/${id}/${conditionToDisplay.condition}`)}>Discuss this condition with others</Button>
        </Flex>



        <h4>Looking for more?</h4>
        <p>If you'd like to understand this condition more or want additional help overcoming it submit a request for help for additional support. <Link to="https://forms.gle/JgudBDb8FKPcK8wGA">Get help with your unique situation. </Link> </p>
        
        <h4>Having something to add?</h4>
        <p>If there is anything here you think can be improved to help someone overcome this condition we'd love to hear it. We may update this page with your input. {<Link to="#">Contribute</Link>}</p>
        {isAdmin && 
        <> 
          <Button size='2' highContrast onClick={() => navigate(`/condition/${id}/edit`)}>Edit</Button>
          <AlertDialog.Root>
              <AlertDialog.Trigger>
                <Button color="red">Delete</Button>
              </AlertDialog.Trigger>
              <AlertDialog.Content maxWidth="450px">
                <AlertDialog.Title>Delete: {conditionToDisplay.condition}</AlertDialog.Title>
                <AlertDialog.Description size="2">
                  Are you sure? This word/phrase will no longer be accessible and deleted from the DB
                  
                </AlertDialog.Description>

                <Flex gap="3" mt="4" justify="end">
                  <AlertDialog.Cancel>
                    <Button variant="soft" color="gray">
                      Cancel
                    </Button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action>
                    <Button variant="solid" color="red" onClick={deleteCondition}>
                      Delete
                    </Button>
                  </AlertDialog.Action>
                </Flex>
              </AlertDialog.Content>
            </AlertDialog.Root>
          </>}
    </div>
    }
    {!conditionToDisplay && 
      <p>Loading..</p>
     }
     { (pageisLocked && loggedInUser!=null) && <>
    
      <p>You must have a subscription or individually purchase the solution to this condition to view it. Click the button below to purchase this condition's solution directly or get a subscription</p>
      <Button onClick={purchaseCondition}>Direct purchase for lifetime access </Button>
      <stripe-pricing-table pricing-table-id="prctbl_1Q7jjDBm8yO68FsgLPJOPObR"
publishable-key="pk_test_51Q5CkLBm8yO68FsgREoyysggMzs6sWfiHublue5zIf7SaaKMaTMagUtoU63dyfogwI4KTpJ2bh4NoAxhUO8r6ELN009BDY8NoS" customer-email={`${loggedInUser}`}>
</stripe-pricing-table>
      </>
     }
      { (pageisLocked && loggedInUser==null) && <>
    
         <p>You must have a subscription or individually purchase the solution to this condition to view it. The social anxiety solution is free for all users with an account. Other solutions are 2$ or are accessible with a subscription. To do this you must first <Link to="/createAccount" >create an account. </Link> </p>
         </>
 }

       { pageisLocked && <><Heading mt='2'>What you get when you purchase</Heading> 
        <Text>2$ Lifetime access to this solution plus access to future enhancments driven by community recommendation and new research findings. Lifetime access to the community group for this solution which lets you submit questions and help requests to our experts. 
        Subcription 15$ access to all existing and future solutions and forums. Access to the solutions is lost when your subscriptin is no longer active. If you purchased a solution and have a subscription, you’ll have access to all your purchased solution even when your subcription ends. </Text>

        <Heading as='h3'>What is this community group?</Heading>
        <Text>A forum(think reddit) for the problem-solution you purchased. You can ask and answer questions, share lessons learned, etc with others workign on the same the psychological problem as you. <Em>If there isn't a lot of activity in the forum and you post questions you will likely get priority support from our experts.</Em>
        </Text>

        <Heading as='h2'>Access to experts</Heading>
        <Text>If you think the solution needs more clarification or improvements you can create a post or comment and share what you’d like us and the community to know. We regularly review communities. You can also submit a dedicated ‘solution feedback’ request that is only available to those that have purchased a solution or have a subscription.  
        </Text>
        </>
   }
  </div>
    
     
  );
}

export default ConditionPage;



