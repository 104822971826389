import { Button, Heading, AlertDialog, Flex } from '@radix-ui/themes'
import {React, useContext, useState}from 'react'
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";
import PostList from './PostList';
import { isLoggedIn } from '../../api/userManagement';
import DataContext  from '../../context/DataContext';






const Forum = () => {
  const { search, setSearch, isUserLoggedIn,setIsUserLoggedIn } = useContext(DataContext);
  const [showLoginSuggesstion, setShowLoginSuggesstion ] = useState(false);



    const { postType,referencingObjectId, objectTitle } = useParams();
    const navigate = useNavigate();
    console.log(postType)
    console.log(referencingObjectId)

    async function createPostHandler(){

      if(isUserLoggedIn)
      {
        console.log(isUserLoggedIn)
        navigate(`/posts/${postType}/${referencingObjectId}/${objectTitle}/createPost`)
      }
      else {
        setShowLoginSuggesstion(true);
      }

     

    }
    //got to fetch the object 
  return (
    <div>
        <Heading>Discussions for {postType}: {objectTitle}</Heading>
       {!isUserLoggedIn ? <Button disabled>Log in to Create posts</Button>: <Button onClick={createPostHandler}>Create Post</Button>}
        <PostList postType={postType} parentObjectName={objectTitle} referencingObjectId={referencingObjectId}></PostList>

        {showLoginSuggesstion && <AlertDialog.Root>
            {/* <AlertDialog.Trigger>
              <Button color="red">Revoke access</Button>
            </AlertDialog.Trigger> */}
            <AlertDialog.Content maxWidth="450px">
              <AlertDialog.Title>Revoke access</AlertDialog.Title>
              <AlertDialog.Description size="2">
                Are you sure? This application will no longer be accessible and any
                existing sessions will be expired.
              </AlertDialog.Description>

              <Flex gap="3" mt="4" justify="end">
                <AlertDialog.Cancel>
                  <Button onClick={()=>setShowLoginSuggesstion(false)}variant="soft" color="gray">
                    Ok
                  </Button>
                </AlertDialog.Cancel>
                <AlertDialog.Action>
            
                </AlertDialog.Action>
              </Flex>
            </AlertDialog.Content>
        </AlertDialog.Root>}
    </div>

    
  )
}

export default Forum