import { Button, Callout, Flex, Link, Text, TextField } from '@radix-ui/themes'
import React from 'react'
import { fetchData, postData, putData, apiURL} from '../../api/fetchData';
import parse from 'html-react-parser';


import { useState, useEffect, useContext, useRef} from "react";
import DataContext from '../../context/DataContext';
import { useNavigate, useLocation } from 'react-router';
import { InfoCircledIcon } from '@radix-ui/react-icons';

const Login = () => {

  const { search, setSearch, isUserLoggedIn,setIsUserLoggedIn, setLoggedInUser } = useContext(DataContext);
   let navigate = useNavigate();
   const location = useLocation();

    const [user, setUser ] = useState(null);
    const [pwd, setPwd ] = useState(null);
    const [loginError, setLoginError ] = useState(false);


    async function login(e) {
        console.log(user)
        console.log(pwd)
    //     //e.preventDefault();
      
        // let apiURL = process.env.REACT_APP_API_SERVER + process.env.REACT_APP_PORT;
    //     const { user, pwd } = req.body;

        const loginDetails = {
          "user": user,
          "pwd": pwd,  
          
      };         
          
            let loginResponse = await postData(apiURL + `/auth/`, loginDetails);
            console.log(loginResponse)
            if(loginResponse?.response?.status >=200)
            {
              setIsUserLoggedIn(true);
              setLoginError(false)
              console.log(user)
              setLoggedInUser(user)
              console.log(location?.state?.pathCameFrom)
              let toGoTo = location?.state?.pathCameFrom
              console.log(toGoTo)
                navigate(toGoTo);

              // navigate(0)
            } else {
              setLoginError(true)
            }
             
              
            //let adminIN = await fetchData( apiURL + `/auth/checkAdminLoggedIn/`);
            // console.log(adminIN)


      
      }

// make login api request which should put cookine in broswer. in pages whre cookies is needd, can do isloggedin and browser should send cookie 
  return (
    <Flex direction="column" gap="3" maxWidth="500px">
    <TextField.Root onChange={(e) => setUser(e.target.value)} radius="none" placeholder="Username" />
    <TextField.Root type='password' onChange={(e) => setPwd(e.target.value)} radius="none" placeholder="Enter password here" />
     
      {/* generate random string, send to user email, then store that string using hash into DB */}
    {/* <label
          Loaded Word/phrase:
          <input
            type="text"
            name="condition"
            value={user}
             onChange={(e) => console.log()}
          />
        </label> */}
    <Button size="3" onClick={login}>Login</Button>
    <Text >Forgot password feature coming soon. Until then, send us an email at clarityapp1@gmail.com saying you need a new password.</Text>
    {/* send them password to their email. creata new password for them */}
  { loginError && <Callout.Root color="red">
    <Callout.Icon>
      <InfoCircledIcon />
    </Callout.Icon>
    <Callout.Text>
      Check your login information. It's not correct
    </Callout.Text>
  </Callout.Root>}

    <Callout.Root color="gray" variant="soft" highContrast>
        <Callout.Icon>
          <InfoCircledIcon />
        </Callout.Icon>
        <Callout.Text>
          If you don't have an account to log in with you can create one <Link href="/createAccount">here.</Link>
        </Callout.Text>
    </Callout.Root>

    
  
  </Flex>
  )
}

export default Login