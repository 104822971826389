import logo from './logo.svg';
import { useParams, useNavigate } from "react-router-dom";
import './Home.css'
import DataContext from './context/DataContext';
import { useState, useEffect, useContext } from "react";
import { fetchData } from './api/fetchData';
import { Flex, Text, Button, Grid, Box, Link as radixLink, Link} from '@radix-ui/themes';
import { FaceIcon, ImageIcon, Link2Icon, SunIcon } from '@radix-ui/react-icons'




function Home() {
  const { searchedForBelief, setSearchedForBelief, setFetchBeliefs, setBeliefList, setConditionListToDisplay } = useContext(DataContext);
  //setSearchedForBelief("sdfsd");
  const [test, setTest] = useState('initial');

  console.log(test);

  const navigate = useNavigate();

  

  const getConditions = async () => {
    //use string provided and search through db list of beleifs and find relevatn matches. provide matches in a list for user to scroll through
  
    //CHECK IF DIDNT GET DATA'
    //HAVE TO ADD FILTER -nvm this is to pull up all relevant beliefs; set request header; let fetch data accept header object as a parm,
  //   axios.get('/users', {
  //     headers: {
  //         'MyCustomHeader1': '1',
  //         'MyCustomHeader2': '2'
  //     }
  // })
    const headers =  {
            'filter': "",
            'MyCustomHeader2': '2'
        }
        console.log(headers);
        let apiURL = (process.env.REACT_APP_API_SERVER!=='dev'? process.env.REACT_APP_API_SERVER : 'belief-api:') + process.env.REACT_APP_PORT;

    let fetchedConditions = await fetchData(apiURL + '/conditions', headers);
    console.log(fetchedConditions);
    //set beleif list which will be used to create belief cards on next page
    setConditionListToDisplay(fetchedConditions?.response?.data);
    console.log(fetchedConditions.response.data);
    navigate('/conditions')
  
  }

  const getLoadedTerms = () => {
    navigate('/loadedWordSearch')
  }

  return (
   //sometyime typeis in beleif they;re looking for
   //toggel and search by problem instead
   
  //  <div className="Home">
  <Grid columns="2"  height='400px' gap="10px"  width="auto"> 
  
    
    <Button size="4" radius="large" variant="solid" highContrast className='homeNavButton' onClick={getConditions}>
    Overcome a condition (ex: Social anxiety)
    </Button>
      {/* <div >
        <button className='homeNavButton'>
           <Link to="/beliefSearch">Search for a belief</Link>
        </button>
      </div> */}
    <Button size="4" radius="large" variant="soft" highContrast style={{height: '100%'}}onClick={getLoadedTerms}>
    Search for a loaded term/phrase
    </Button>
      {/* <div >
       <button className='homeNavButton' onClick={getLoadedTerms} >
            Search for a loaded term/phrase
        </button> 
      </div>
        */}

<Button size="4" radius="large" variant="solid" highContrast style={{height: '100%'}} onClick={(e) => navigate('/beliefSearch')}>
    Dismantle a belief
    </Button>
      {/* <div >
        <button className='homeNavButton' onClick={getConditions}>
            Overcome a condition (ex: Social anxiety)
        </button>
      </div> */}

    <Link href="https://medium.com/@theclarityapp"> <Button style={{
           'grid-column': `1 / span 2`,
            width: '100%',
            'justify-self': 'center'
          
        }}  size="4" radius="large" variant="soft" highContrast  className='homeNavButton' >
    Clarity & Peace providing Insights
    </Button>
    </Link>
      {/* <div >
        <button className='homeNavButton'>
           <Link to="/insights">View Insights</Link>
        </button>
      </div> */}
      <Link style={{
           'grid-column': `1 / span 2`,
            width: '75%',
            'justify-self': 'center'
          
        }} href="https://becomeaforce.gumroad.com/l/tkktsg">
        <Button  radius="Full"  className='homeNavButton' style={{
           'grid-column': `1 / span 2`,
            width: '100%',
            'justify-self': 'center'
          
        }} size="4" variant="solid" highContrast >Eliminate a Trigger<Link2Icon />
        </Button>
      </Link>
       
       

        {/* onClick={(e) => navigate('https://becomeaforce.gumroad.com/l/tkktsg')} */}
{/*         
      <Box  gridColumn="1 / 3">
        <Button style={{
          // 'grid-column': `1 / span 2`
          width: '75%'
          
        }} radius="Full"   size="4" variant="solid" highContrast onClick={(e) => navigate('https://docs.google.com/document/d/14VgmPCRVvyXL68ejNk4uV2GH0tVYbnj3ZMNHzHe1tWM/edit?usp=drive_link')}>
              Eliminate a Trigger
          </Button>
      </Box>
       */}
        {/* <button className='homeNavButton'>
            <Link to="https://docs.google.com/document/d/14VgmPCRVvyXL68ejNk4uV2GH0tVYbnj3ZMNHzHe1tWM/edit?usp=drive_link">eliminate a trigger </Link>
        </button>     */}
    {/* // </div> */}
    </Grid>
    
    
   
  );
}

export default Home;

