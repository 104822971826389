import { Button, Callout, Flex, Text, TextField } from '@radix-ui/themes'
import React from 'react'
import { fetchData, postData, putData,apiURL } from '../api/fetchData';
import parse from 'html-react-parser';


import { useState, useEffect, useContext, useRef} from "react";
import DataContext from '../context/DataContext';
import { useNavigate } from 'react-router';
import { InfoCircledIcon } from '@radix-ui/react-icons';

const CreateAccount = () => {

  const { search, setSearch, isUserLoggedIn,setIsUserLoggedIn } = useContext(DataContext);
   let navigate = useNavigate();

    const [user, setUser ] = useState(null);
    const [pwd, setPwd ] = useState(null);
    const [createError, setCreateError] = useState(false)
    const [email, setEmail ] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)



    async function createAccount(e) {
    //     console.log(user)
    //     console.log(pwd)
        e.preventDefault();
      
    //     let apiURL = process.env.REACT_APP_API_SERVER + process.env.REACT_APP_PORT;
    // //     const { user, pwd } = req.body;

        const accountDetails = {
          "user": user,
          "pwd": pwd, 
          "email": email
          
      };         
          
            let createResponse = await postData(apiURL + `/register/user`, accountDetails);
            console.log(createResponse)
            if(createResponse?.response?.status >=200)
            {
              navigate('/login')
            }
            else {
                setCreateError(true)
                console.log(createResponse?.response?.response?.status)
                if(createResponse?.response?.response?.status ===409)
                    setErrorMessage("username already exists")


              }
               
              
            //let adminIN = await fetchData( apiURL + `/auth/checkAdminLoggedIn/`);
            // console.log(adminIN)


      
      }

// make login api request which should put cookine in broswer. in pages whre cookies is needd, can do isloggedin and browser should send cookie 
  return (
    <Flex direction="column" gap="3" maxWidth="500px">
      <form onSubmit={createAccount}>
      <TextField.Root required  type="email"  onChange={(e) => setUser(e.target.value)} radius="none" placeholder="Username" />
      <Text>Note: Must be an email so  we can email you your password if you ever forget. We won't send you emails without your consent.</Text>
    <TextField.Root type="password" required onChange={(e) => setPwd(e.target.value)} radius="none" placeholder="password" />
    {/* <TextField.Root required type="email" onChange={(e) => setEmail(e.target.value)} radius="none" placeholder="Email" /> */}
     

      {/* generate random string, send to user email, then store that string using hash into DB */}
    {/* <label
          Loaded Word/phrase:
          <input
            type="text"
            name="condition"
            value={user}
             onChange={(e) => console.log()}
          />
        </label> */}
    <Button >Create account</Button>
    { createError && <Callout.Root color="red">
    <Callout.Icon>
      <InfoCircledIcon />
    </Callout.Icon>
    <Callout.Text>
      {`Create account failed. Please try again. If it keeps failing please contact us at ${process.env.REACT_APP_EMAIL}`}
      <br></br>
      {errorMessage && `Error is: ${errorMessage}`}
    </Callout.Text>
  </Callout.Root>}
    <Callout.Root color="gray" variant="soft" highContrast>
      <Callout.Icon>
        <InfoCircledIcon />
      </Callout.Icon>
      <Callout.Text>
      After creating an account you will be sent to the login page to log in if you wish.
      </Callout.Text>
    </Callout.Root>
      </form>
    

  
  </Flex>
  )
}

export default CreateAccount