import logo from './logo.svg';
import { useParams, Link, useNavigate } from "react-router-dom";
import './App.css';
import BeliefList from './BeliefList';
import DataContext from './context/DataContext';
import { useState, useEffect, useContext } from "react";
import { fetchData } from './api/fetchData';
import { TextField, Box, Flex, Button, TextArea, Heading, Text} from '@radix-ui/themes';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'



function LoadedWordSearch() {
  const { setLoadedWordList, setSearchedForLoadedWord, searchedForLoadedWord} = useContext(DataContext);
  //setSearchedForBelief("sdfsd");
  const [test, setTest] = useState('initial');

  console.log(test);

  const navigate = useNavigate();

  const searchForLoadedWord = async (loadedWord) => {
    console.log(searchedForLoadedWord)
    //console.log(`in searchfor beleif method and got ${test}` + searchedForBelief);
    //use string provided and search through db list of beleifs and find relevatn matches. provide matches in a list for user to scroll through

    //CHECK IF DIDNT GET DATA'
    //HAVE TO ADD FILTER -nvm this is to pull up all relevant beliefs; set request header; let fetch data accept header object as a parm,
  //   axios.get('/users', {
  //     headers: {
  //         'MyCustomHeader1': '1',
  //         'MyCustomHeader2': '2'
  //     }
  // })
    const headers =  {
            'filter': searchedForLoadedWord,
            'MyCustomHeader2': '2'
        }
        let apiURL = (process.env.REACT_APP_API_SERVER!=='dev'? process.env.REACT_APP_API_SERVER: 'belief-api:') + process.env.REACT_APP_PORT;

    // let fetchedLoadedWords = await fetchData('http://localhost:4500/loadedWords', headers);
        let fetchedLoadedWords = await fetchData(apiURL + '/loadedWords', headers);

    console.log(fetchedLoadedWords);
    //set word list which will be used to create cards on next page
    setLoadedWordList(fetchedLoadedWords?.response?.data);
    console.log(fetchedLoadedWords.response.data);

     navigate('/loadedWords')
    
  }

  return (
   //sometyime typeis in beleif they;re looking for
   //toggel and search by problem instead
  //  <>
  //   <input
  //       id="searchLoadedWord"
  //       type="text"
  //       placeholder="Search for a loaded word/phrase"
  //       //value={search}
  //       onChange={(e) => setSearchedForLoadedWord(e.target.value)}
  //     />  
  //     <button onClick={searchForLoadedWord}>Search</button>
  //     <h2>Note that this search feature is still under improvement</h2>
  //   </>
    <>
    <Flex justify="center" > 
     {/* can center this horizontally in the flex container and since its row flex the next element will be after it*/}
     <Box width="500px">
       <TextField.Root placeholder="Leave empty to see all loaded Words" size="3"  onChange={(e) => setSearchedForLoadedWord(e.target.value)}>
         <TextField.Slot>
           <MagnifyingGlassIcon height="16" width="16" />
         </TextField.Slot>
       </TextField.Root>
     </Box>
     <Button variant="solid" size='3' onClick={searchForLoadedWord}>
          Search
       </Button>
    </Flex>

    <h2>Note that this search feature is still under improvement</h2>
    <Text>Read <a href='https://medium.com/@theclarityapp/what-is-a-loaded-term-phrase-421988ab5149'>this</a> to learn what a loaded/term phrase is</Text>
    
     </>
    
   
  );
}

export default LoadedWordSearch;

