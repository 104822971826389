import logo from '../logo.svg';
import '../App.css';
import parse from 'html-react-parser';
import { Flex, Text, Button, Card, Box, Heading } from '@radix-ui/themes';
import { InfoCircledIcon, LockClosedIcon } from '@radix-ui/react-icons'




function searchForBelief(belief) {
  //move to belief list page; iterate through all beleifs and generate a single beleif component for each matching belief
  //use string provided and search through db list of beleifs and find relevatn matches. provide matches in a list for user to scroll through
}
function SingleConditionCard({showLock, condition, ourDefinition, thirdPartyDefinition, conditionId, hasAcess}) {
    
    //console.log(parse(ourDefinition));  
  // console.log(showLock)
  return (
   //sometyime typeis in beleif they;re looking for
   //toggel and search by problem instead
 

   
  //  <div className='beliefCard'>
  //   <h1>{condition} </h1>
  //   {/* <h2>Our Definition </h2> */}
  //    {ourDefinition && parse(ourDefinition)}
  //    {/* <h2>Definition from {thirdPartyDefinition.sourceName} </h2>
  //    <p>{thirdPartyDefinition.definition}</p>
  //   <h3>Dismantle of the belief:</h3> 
  //     <p>substring 100 characters...</p>
  //     <Link>Link to definition from 3rd party:</Link> */}
  //     {/* make request to get beliefs */}
  //  </div>

<Box maxWidth="400px">
<Card variant='classic' size='3'>
  <Flex direction="column" gap="" align="">
   <>{showLock &&  <LockClosedIcon/>}</>
      <Heading mb="" as="h1" >
      {condition}
      </Heading>
      {/* <h3>Why its a loaded word: </h3> */}
      {ourDefinition && <Text as=""  mt="" color="gray" >  {parse(ourDefinition.substring(0,1000))} </Text> }
      <Button mt="2" color="gray" variant="soft" style={{'align-self': 'center'}} >Click for more</Button>
  </Flex>
</Card>
</Box>
     
  );
}

export default SingleConditionCard;

